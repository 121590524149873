import Button from "components/Button";
import CTABanner from "components/CTABanner";
import SEO from "components/SEO";
import { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "layout";
import React from "react";
import { BasePageContext, TemplatePageProps } from "utils/types";
import { Carousel } from "react-responsive-carousel";
import LeftCarouselArrow from "components/LeftCarouselArrow";
import RightCarouselArrow from "components/RightCarouselArrow";

const main = require("images/studio/hillary-taylor-studio.jpg").default;
const studio1 = require("images/studio/studio1.jpg").default;
const studio2 = require("images/studio/studio2.jpg").default;
const studio3 = require("images/studio/studio3.jpg").default;

export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;

  return (
    <Layout appData={appData}>
      <div className="container--large spaced-100">
        <h1 className="with-divider">Studio</h1>

        <div className="meet-hillary">
          <div className="image">
            <img src={main} alt="Hillary Taylor studio" />
          </div>
          <div className="content">
            <p>Unapologetically joyful. Optimistic. Authentic.</p>

            <p>
              These are some of the characteristics that embody Hillary W
              Taylor’s work. Her namesake firm, Hillary W Taylor Interiors, is
              founded on the belief that beauty is in nature and in people.
            </p>

            <p>
              Whether it is a sprawling ranch home, city pied-à-terre or summer
              residence in New England, Hillary aims to create lighthearted,
              enduring spaces that solve for the beautiful chaos of life. Her
              full service firm thrives off of collaborative partnerships,
              finessing microscopic details and injecting each room with
              something borrowed, old, or properly handmade.
            </p>

            <p>
              The firm’s work has graced the pages of numerous glossy magazines.
              In 2021, Hillary was named as one of the “Rising Stars of Design”
              by the national publication Traditional Home.
            </p>
          </div>
        </div>

        <div className="spaced-50"></div>

        <Carousel
          swipeable
          emulateTouch
          showThumbs={false}
          showStatus={false}
          renderArrowPrev={LeftCarouselArrow}
          renderArrowNext={RightCarouselArrow}
        >
          <div>
            <img src={studio1} alt="Studio" />
          </div>
          <div>
            <img src={studio2} alt="Studio" />
          </div>
          <div>
            <img src={studio3} alt="Studio" />
          </div>
        </Carousel>
      </div>
    </Layout>
  );
};

export const Head: HeadFC = () => <SEO title="Studio"></SEO>;
